.home{
    background-color: aquamarine;
}

.image-profile{
    object-fit: cover;
    width:100%;
    height:100%;
}

.div-profile{
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}

.div-profile-birthday{
  display: inline-block;
  padding: 5px;
  box-sizing: border-box;
  height: '5vh';
}