.loader {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid #003876;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    position: fixed;
    margin-left: 45%;
    margin-top: 20%;
}

    .loader::after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        border-left: 4px solid #ed232a;
        border-bottom: 4px solid transparent;
        animation: rotation 0.5s linear infinite reverse;
    }

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
