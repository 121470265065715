/* Quitar el hover para los botones específicos */
.swal-button--cambiar:hover, .swal-button--cambiar:focus {
  background-color: initial !important;
  box-shadow: none !important;
  color: #198754;
}

.swal-button--cancelar:hover, .swal-button--cancelar:focus {
  background-color: initial !important;
  box-shadow: none !important;
  color: #dc3545;
  border-color: #dc3545;
}

.swal-button--usarFirma:hover, .swal-button--usarFirma:focus {
  background-color: initial !important;
  box-shadow: none !important;
  color: rgb(0, 56, 118)
}
