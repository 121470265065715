/* BOTON PERSONALIZADO */
.btn-outline-blue {
  color: #003876;
  border-color: #003876;
  height: 5vh;
  width: 8vw;
  font-size: calc(0.38em + 0.38vw);
}

.btn-outline-blue:hover {
  color: #fff;
  background-color: #003876;
  border-color: #003876;
}

.btn-check:checked+.btn-outline-blue,
.btn-check:active+.btn-outline-blue,
.btn-outline-blue:active,
.btn-outline-blue.active,
.btn-outline-blue.dropdown-toggle.show {
  color: #fff;
  background-color:  #003876;
  border-color:  #003876;
}

.btn-check:checked+.btn-outline-blue:focus,
.btn-check:active+.btn-outline-blue:focus,
.btn-outline-blue:active:focus,
.btn-outline-blue.active:focus,
.btn-outline-blue.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}

.hr-card {
  margin: calc(0.11vw + 0.11vh)
}

.p-resumenAsistencia {
  font-size: 15px;
}

.h5-resumenAsistencia {
  font-size: 18px;
}

.mt-cardAsistencia {
  margin-top: calc(2vw + 3.5vh);
}

.col-0-5 {
  flex: 0 0 auto;
  width: 4.45%;
}

/* .mt-10 {
  margin-top: 72.5px !important;
  ;
} */

.text-14 {
  font-size: 14px;
}

.text-13 {
  font-size: 13px;
}

.col--1 {
  flex: 0 0 auto;
  width: 4.333333%;
}

.col-departamentos {
  flex: 0 0 auto;
  width: 27.333333%;
}

.col-w11{
  flex: 0 0 auto;
  width: 11.666667%;
}

.total-height{
  height: 4vh;
}