.main {
    margin-top: 10px;
    // margin-left: 10px;
    // margin-right: 10px;
    // width: 100%;

    .table {
        // width: 100%;
        // height: 60%;
        margin-top: 5px;
    }
}